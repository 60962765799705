.swiper {
  user-select: none;
  width: 100%;
  display: flex;

  .swiper-slide-desktop {
    width: 122px;
  }
}

.title {
  line-height: 22px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .title {
    line-height: 16px;
    gap: 2px;
  }
}
